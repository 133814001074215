import React from "react";
import Layout from "../components/layout";
import ring from "../images/ring.jpeg";

const ThankYou = () => (
  <Layout background={ring}>
    <h1>Thank You</h1>
    <p>We truly appreciate it!</p>
  </Layout>
);

export default ThankYou;
